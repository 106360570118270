<template>
    <div class="z-20 w-100 flex flex-col items-center justify-center">
        <p class="uppercase text-center leading-none font-blackMango font-black text-districtsCover text-white tracking-wide">
            {{ district.name }}</p>
        <p class="text-md w-2/3 text-center text-white font-blackMango tracking-wider w-2/3 w-3/5">{{ $t('districts.GeneralSubtitle')}}
        </p> 

        <div class="text-white mt-4">
            <div class="flex flex-col items-center" v-if="district.properties != null">
                
                <p class="font-blackMango tracking-wider text-lg font-black" v-if="false">{{ propertiesQTY }} </p>
                <p class="uppercase text-sm font-light tracking-wider" v-if="false">PROPERTIES</p>
                <!-- <p class="uppercase text-sm font-light tracking-wider">{{ district.homepage.info.properties }} PROPERTIES</p> -->
            </div>
        </div>
        <div>   
            <a @click="generateLink(district)">
                <ButtonArrow  bgColor="bronze" class="z-40 mt-6" :text="$t('buttons.discover')" />
            </a>
        </div>
    
        </div>
</template>

<script>
    import ButtonArrow from "@/components/buttons/ButtonArrow.vue";

    export default {
        name: "DistrictsInfo",
        props: ["district", "propertiesQTY"],
        components: {
            ButtonArrow
        },
        methods:{
            generateLink(d){
                let isEnglish = this.$i18n.locale == 'en'
                let district = d.name
                if(isEnglish){
                    window.location.href = this.$ego_redirecter() + '/en-gb/properties?loc=' + district.toUpperCase()
                }else{
                    window.location.href = this.$ego_redirecter() + '/imoveis?loc=' + district.toUpperCase()
                }
                //this.$router.push({ name: 'districts', params: { lang: this.$i18n.locale, slug: id }})
            }
        }
    }
</script>