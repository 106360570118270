<template>
    <div class="w-screen mt-8 md:mt-16 lg:mt-24">
        <!-- begin::title section -->
        <div class="flex items-center flex-col px-5 md:px-0">
            <p class="md:text-sectionTitle text-lg w-full text-left md:text-center font-understock">
                {{ $t('homepage.listings.title') }}</p>
            <p class="text-baselg w-full text-left md:text-center font-blackMango tracking-wider md:w-2/3">
                {{ $t('homepage.listings.subtitle') }}</p>

            <!-- <router-link :to="{ name: 'listings', params: { lang: this.$i18n.locale, data: '0' }}">
                <ButtonHomepage bgColor="black" class="md:flex hidden mt-6" :text="$t('buttons.seeAllProperties')" />
            </router-link>
            -->
            <ButtonHomepage @click="goToEgoProperties()" bgColor="black" class="md:flex hidden mt-6" :text="$t('buttons.seeAllProperties')" />
        </div>
        <!-- end::title section -->

        <!-- begin::propeties -->
        <div v-if="false" class="hidden lg:flex flex-col justify-center mt-6 md:w-full px-5 md:px-10 lg:px-0 lg:w-10/12 inset-x-0 mx-auto">
            <div :key="realoadingDiv" class="grid md:grid-cols-2 lg:grid-cols-3 py-10 px-14 lg:p-14 text-md bg-white mb-3 mt-4 rounded-2xl gap-4 lg:gap-6 lg:gap-y-8 lg:gap-y-10 relative w-full">
                <a
                    v-for="item in itemsList" 
                    :key="item.id+'ZCX'"
                    @click="generateLink(item.id)" 
                >
                    <PropertyCardBig 
                        :baths="new String(item.kpis.baths)" 
                        :rooms="new String(item.kpis.beds)" 
                        :garage="new String(item.parking)"
                        :area="new String(item.kpis.m2)"
                        :img="$mediaFile('properties', item.id , item.media.cover)"
                        :tag="item.tag" 
                        :location="$processLanguage(item.location)"
                        :reference="item.reference"
                        :cityAddress="getCityAddress(item)"
                        :price="item.hide_price == 1 ? $t('price_under_request') : $currency(new String(item.price))" 
                        color="darkgray" 
                        :dev_id="item.id"/>
                </a>
                <!-- begin::navication arrows-->
                <button class="absolute left-5 z-40 inset-y-0 my-auto" @click="decrementListingIndexShow">
                    <inline-svg class="transform rotate-180" width="1.2rem" height="1.2rem"
                        src="/svg/arrow/arrowBall.svg" />
                </button>

                <button class="absolute right-5 z-40 inset-y-0 my-auto" @click="incrementListingIndexShow">
                    <inline-svg class="transform origin-center rotate-0" width="1.2rem" height="1.2rem"
                        src="/svg/arrow/arrowBall.svg" />
                </button>
                <!-- end::navigation arrows -->
                <div v-show="viewMoreOverlay" class="absolute w-full h-full view-more-overlay z-30 flex items-center justify-center">
                    <router-link :to="{ name: 'listings', params: { lang: this.$i18n.locale, data: '0' }}">
                        <ButtonHomepage bgColor="bronze" class="md:flex hidden mt-6" :text="$t('buttons.viewAll')" />
                    </router-link>
                </div>
            </div>
            <!-- end::propeties -->

            <!-- begin::navigation -->
            <NavigationIndex :currentNumber="myActualPage" :totalNumber="Math.ceil(items.length/listingsList)" color="bronze" />
            <!-- end::navigation -->
        </div>
          <!-- begin::propeties mobile -->
        <div class="lg:hidden  mt-5 md:mt-8 pl-5 md:pl-10 w-screen block relative">
            <swiper :options="swiperOption" class="swiper" ref="swiperDevelopments">
                <swiper-slide v-for="item in mobileItemsLis" :key="item.id+'qwe'">
                    <a
                    @click="generateLink(item.id)" 
                    >
                        <PropertyCardBig 
                            :baths="new String(item.kpis.baths)" 
                            :rooms="new String(item.kpis.beds)" 
                            :garage="new String(item.parking)"
                            :area="new String(item.kpis.m2)"
                            :img="$mediaFile('properties', item.id , item.media.cover)"
                            :reference="item.reference"
                            :location="$processLanguage(item.location)"
                            :cityAddress="getCityAddress(item)"
                            :tag="item.tag" 
                            :price="item.hide_price == 1 ? $t('price_under_request') : $currency(new String(item.price))" 
                            :item="item"
                            color="darkgray"
                        :dev_id="item.id" />
                </a>
                </swiper-slide>
            </swiper>
            <router-link :to="{ name: 'listings', params: { lang: this.$i18n.locale, data: '0' }}">
                <ButtonHomepage bgColor="black" class="md:hidden block mt-5" :text="$t('buttons.seeAll')" />
            </router-link>
        </div>
        <!-- end::propeties mobile -->
    </div>
</template>

<style lang="scss" scoped>
    .swiper-slide {
      width: 70%;
      .swiper-slide:last-child {
          width: 100%;
      }
      /* tablet */
      @media (min-width: 768px) {
          width: 30%;
          .swiper-slide:last-child {
              width: 100%;
          }
      }
    }
    .view-more-overlay{
        background: rgba(255,255,255,0.7);
    }
</style>

<script>
    import PropertyCardBig from "@/components/listings/cards/PropertyCardBig";
    import ButtonHomepage from "@/components/buttons/ButtonHomepage.vue";
    import NavigationIndex from "@/components/navigationIndex/NavigationIndex.vue";

    import {
        Swiper,
        SwiperSlide
    } from 'vue-awesome-swiper'
    import 'swiper/swiper-bundle.css'

    export default {
        name: "Listings",
        components: {
            PropertyCardBig,
            ButtonHomepage,
            Swiper,
            SwiperSlide,
            NavigationIndex
        },
        props: ['items', 'listingsIndexShow', 'pagination'],
        data: function () {
            return {
                listings: null,
                listingsList: 6,
                listingsListMobile: 8,
                swiperOption: {
                    loop: false,
                    slidesPerView: 'auto',
                    spaceBetween: 15,
                },
                variableToIncrement: 6,
                realoadingDiv: 0,
                myActualPage: 0,
                viewMoreOverlay: false
            }
        },
        created(){
            this.myActualPage = this.pagination
            
            if(this.myActualPage == Math.ceil(this.items.length/this.listingsList)) {
                this.viewMoreOverlay = true;
                /*var that = this;
                setTimeout(function(){
                    that.viewMoreOverlay = true;
                },2000)*/
            }
        },
        methods:{
            goToEgoProperties(){
                let isEnglish = this.$i18n.locale == 'en'
                if(isEnglish){
                    window.location.href = this.$ego_redirecter() + '/en-gb/properties'
                }else{
                    window.location.href = this.$ego_redirecter() + '/imoveis'
                }
            },
            getCityAddress(i){
                if(i.administrative_area_level_2 == '' || i.administrative_area_level_2 == null)
                    return ''
                
                if(i.political != null)
                    return i.political + ', ' + i.administrative_area_level_3

                return i.administrative_area_level_2 + ', ' + i.administrative_area_level_3
            },
            generateLink(id){
                this.$router.push({ name: 'listing', params: { lang: this.$i18n.locale, slug: id }})
            },
            incrementListingIndexShow(){
                if(this.listingsIndexShow[1] >= this.items.length)
                    return this.viewMoreOverlay = true;

                this.myActualPage = this.myActualPage + 1

                this.listingsIndexShow[0] += this.variableToIncrement
                this.listingsIndexShow[1] += this.variableToIncrement
            
                this.forceRender()

                
            },
            decrementListingIndexShow(){               
                if(this.listingsIndexShow[0] == 0)
                    return
                    
                this.myActualPage = this.myActualPage - 1

                this.listingsIndexShow[0] -= this.variableToIncrement
                this.listingsIndexShow[1] -= this.variableToIncrement

                this.forceRender()
            },
            forceRender(){
                this.$emit('reload', this.listingsIndexShow, "listings", this.myActualPage)
            }
        },
        computed: {
            itemsList(){
                return this.items.slice(this.listingsIndexShow[0], this.listingsIndexShow[1])
            },
            mobileItemsLis(){
                return this.items
            }
        }
    };
</script>